import React from 'react';

export default function Intro() {
  return (
    <section className="intro-section" id="start">
      <div className="container-fluid bg-grey-dark content-section">
        <div className="row">
          <div className="col-12 col-lg-6 block-padding__left">
            <h2 className="font-size__xl mb-5 text-secondary">
              Es hora de actualizar
            </h2>
            <p>
              Magento 2 es la plataforma líder en proyectos e-commerce B2B. Su potencial para integrar las diferentes herramientas de gestión de pedidos, 
              gestión de clientes, así como gestión de productos, hace de Magento la plataforma de venta online perfecta para este tipo de proyectos.
            </p>
            <p>
              En Beköme tenemos experiencia en proyectos B2B y hemos desarrollado distintos módulos que nos facilitan la integración con tu ERP o sistema de 
              gestión, permitiendo un time to market más rápido y con garantías.
            </p>
          </div>
          <div className="col-12 col-lg-4 d-md-none offset-lg-1 d-lg-block block-padding__right">
            <img className="responsive" src="https://res.cloudinary.com/drjo0cbtf/image/upload/f_auto,q_auto/v1652165823/bekome/migracion-upgrade-magento_dakjdn.svg" alt="Migración magento 2" title="Migración magento 2" width="100%" />
          </div>
        </div>
      </div>
      <div className="container-fluid content-section">
        <div className="row">
          <div className="col-12 col-lg-6 d-md-none d-lg-block block-padding__left">
            <img className="responsive" src="https://res.cloudinary.com/drjo0cbtf/image/upload/f_auto,q_auto/v1652166065/bekome/desarrollo-negocio-magento_b52plr.svg" alt="Desarrollo enfocado a negocio" title="Desarrollo enfocado a negocio" width="80%" />
          </div>
          <div className="col-12 col-lg-6 block-padding__left">
            <h2 className="font-size__xl mb-5 text-secondary">
                Desarrollo enfocado a negocio
            </h2>
            <p>
              Nuestro equipo habla tu idioma. Sabemos lo que cuesta hacer crecer tu negocio y entendemos lo frustrante que pueden llegar a ser algunos procesos.
            </p>
            <p>
              Por eso nuestro equipo se enfoca en soluciones que aporten valor a tu empresa, ahorren tiempo o simplifiquen un proceso.
            </p>
            <p>
              Además, no prometemos nada que no podamos cumplir. Nos preguntamos el porqué de las cosas y preferimos ofrecerte distintas soluciones a decir sí a todo.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
