import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Contact from '../components/Contact';
import SEO from '../components/Seo';
import Intro from '../components/Migration/Intro';
import Projects from '../components/Proyectos';
import Calendly from '../components/Calendly';

class MigracionMagento2 extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <SEO
          title="Migración a Magento 2 y actualizaciones"
          description="Somos especilistas en migraciones y actualizaciones de versiones. Deja en manos de una agencia especializada la migración de tu proyecto y no te compliques."
          canonicalUrl="https://www.bekome.digital/migracion-magento-2/"
        />

        <header className="masthead secondary migracion-magento-2 d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1">Migración a Magento 2</h1>
            <h3 className="mb-5">
              Actualiza tu proyecto y aprovecha el potencial
            </h3>
          </div>
          <div className="overlay"></div>
        </header>

        <Intro />

        <Projects />

        <Calendly />

        <Contact />

        <Footer />
      </LayoutEs>
    );
  }
}

export default MigracionMagento2;
